<template>
    <div class="page">

        <Head />
        <Sidebar />
        <div class="body-main">
            <breadInfo />
            <div class="container bg flex-1 flex-v">
                <div class="pub-tt m15">
                    <div class="tabs">
                        <router-link to="/set-jiliangbiao" class="lk">计量表</router-link>
                        <router-link to="/set-sensor" class="lk">传感器</router-link>
                        <router-link to="/set-virtual" class="lk">虚拟表</router-link>
                        <router-link to="" class="lk cur">离线表</router-link>
                        <router-link to="/set-outlineFile" class="lk">离线表文件</router-link>
                        <router-link to="/set-gaugesManage" class="lk">绑定数据点</router-link>
                    </div>
                    <div class="dix"></div>
                </div>
                <div class="p025 top-bar">
                    <el-form ref="form" class="top-form" :model="form">
                        <el-row :gutter="20">
                            <el-col :span="4">
                                <el-form-item>
                                    <el-select v-model="equimentId" placeholder="选择设备">
                                        <el-option v-for="item in equiments" :key="item.value" :label="item.name"
                                            :value="item.id" />
                                        <el-option label="未选择" value="-1"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="20">
                                <div class="flex flex-pack-justify">
                                    <button class="ann annbg1" @click="getOutlineMeterList">
                                        查询
                                    </button>
                                    <button class="ann annbg1 addbtn" @click="onAdd()">
                                        添加离线表
                                    </button>
                                </div>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div class="flex-1 flex-v p025">
                    <div class="table flex-1">
                        <el-table :data="tableData" height="100%" v-loading="loading"
                            element-loading-background="rgba(0, 0, 0, 0.8)">
                            <el-table-column prop="id" width="60" label="ID">
                            </el-table-column>
                            <el-table-column prop="name" label="名称" width="260">
                            </el-table-column>
                            <el-table-column prop="des" label="描述"> </el-table-column>
                            <el-table-column prop="powerCategoryName" label="能源类型">
                            </el-table-column>
                            <el-table-column prop="max" label="计量表上限"> </el-table-column>
                            <el-table-column prop="min" label="计量表下限"> </el-table-column>
                            <el-table-column label="操作" width="150">
                                <template slot-scope="scope">
                                    <span class="lk c-main" @click="handleEdit(scope.$index, scope.row)">修改</span>
                                    <span class="lk c-red" @click="handleDelete(scope.$index, scope.row)">删除</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <el-pagination @current-change="handleCurrentChange" v-model:current-page="pageInfo.pageNum"
                        layout="prev, pager, next, jumper,total" :total="pageInfo.total" :page-size="pageInfo.pageSize">
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 添加离线表-->
        <el-dialog :visible.sync="addPop" width="40%">
            <div class="pop">
                <div class="toptt">
                    <span class="backbtn">添加离线表</span>
                    <i class="el-icon-close close" @click="addPop = false"></i>
                    <div class="dix"></div>
                </div>
                <div class="detail">
                    <el-form ref="form" :model="addForm" label-width="120px">
                        <el-row :gutter="10">
                            <el-col :span="16">
                                <el-form-item label="名称:">
                                    <el-input v-model="addForm.name"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="10">
                            <el-col :span="12">
                                <el-form-item label="能源类型:">
                                    <el-select v-model="addForm.leixing" placeholder="选择能源类型">
                                        <el-option v-for="item in energyTypeList" :key="item.id" :label="item.name"
                                            :value="item.id" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="10">
                            <el-col :span="12">
                                <el-form-item label="上限:">
                                    <el-input v-model="addForm.shangxian"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="下限:">
                                    <el-input v-model="addForm.xiaxian"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="10">
                            <el-col :span="24">
                                <el-form-item label="所属设备:">
                                    <el-select v-model="addForm.shebei" multiple placeholder="选择所属设备">
                                        <el-option v-for="item in equiments" :key="item.id" :label="item.name"
                                            :value="item.id" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="10">
                            <el-col :span="24">
                                <el-form-item label="描述:">
                                    <el-input v-model="addForm.miaoshu"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div class="btngroup">
                            <button class="ann confirm" @click="onSubmit">确定</button>
                            <button class="ann back" @click="addPop = false">返回</button>
                        </div>
                    </el-form>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Head from "@/components/head.vue";
import Sidebar from "@/components/sidebar.vue";
import breadInfo from "@/components/breadInfo.vue";
import { getEquipList, getEnergyList } from "@/utils/common";
import {
    outlineMeterList,
    delOutlineMeter,
    addOutlineMeter,
    editOutlineMeter,
} from "@/api/meter";
export default {
    data() {
        return {
            editId: -1,
            energyTypeList: [],
            equimentId: "",
            equimentName: "未选择",
            equiments: [],
            tableData: [],
            pageInfo: {
                total: 0,
                pageSize: 10,
                pageNum: 1,
            },
            loading: false,
            addPop: false,
            addForm: {
                name: "",
                leixing: "",
                shangxian: "",
                xiaxian: "",
                shebei: "",
                miaoshu: "",
            },
        };
    },
    components: {
        Head,
        Sidebar,
        breadInfo,
    },
    created() {
        this.equiments = getEquipList();
    },
    mounted() { },
    watch: {},
    methods: {
        handleEdit(index, row) {
            (this.addForm = {
                name: "",
                leixing: "",
                shangxian: "",
                xiaxian: "",
                shebei: [],
                miaoshu: "",
            }),
                (this.addForm.name = row.name);
            this.addForm.leixing = row.powerCategoryId;
            this.addForm.miaoshu = row.des;
            this.addForm.shangxian = row.max;
            this.addForm.xiaxian = row.min;
            if (row.deviceIds != "") {
                this.addForm.shebei = row.deviceIds.split(",").map(Number);
            }
            this.editId = row.id;
            this.addPop = true;
            this.energyTypeList = getEnergyList();
        },
        handleDelete(index, row) {
            this.$confirm("此操作将永久删除该设备, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.tableData.splice(index, 1);
                    this.deleteOutlineMeter(row.id);
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消操作",
                    });
                });
        },
        handleCurrentChange(val) {
            this.pageInfo.pageNum = val;
            this.getOutlineMeterList();
        },
        //获取离线表列表
        getOutlineMeterList() {
            this.loading = true;
            let param = {
                pageNum: this.pageInfo.pageNum,
                pageSize: this.pageInfo.pageSize,
                tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,
                deviceId: this.equimentId,
            };
            if (this.equimentId == "-1") {
                delete param.deviceId;
            }
            outlineMeterList(param).then((res) => {
                if (res.data.code == 0) {
                    this.pageInfo.total = res.data.total;
                    this.tableData = res.data.rows;
                } else {
                    this.$message.warning(res.data.msg);
                }
                this.loading = false;
            });
        },
        //删除具体离线表
        deleteOutlineMeter(id) {
            let param = {
                ids: String(id),
            };
            delOutlineMeter(param).then((res) => {
                if (res.data.code == 0) {
                    this.$message({
                        type: "success",
                        message: "删除成功!",
                    });
                } else {
                    this.$message.warning(res.data.msg);
                    this.$message({
                        type: "info",
                        message: "已取消操作",
                    });
                }
            });
        },
        //添加设备弹窗
        onAdd() {
            this.editId = -1;
            (this.addForm = {
                name: "",
                leixing: "",
                shangxian: "",
                xiaxian: "",
                shebei: "",
                miaoshu: "",
            }),
                (this.addPop = true);
            this.energyTypeList = getEnergyList();
        },
        //添加/修改设备
        onSubmit() {
            if (this.addForm.name == "") {
                this.$message.warning("请填写离线表名");
            } else if (this.addForm.leixing == "") {
                this.$message.warning("请填写能源类型");
            } else {
                let energyName = "";
                for (let i = 0; i < this.energyTypeList.length; i++) {
                    if (this.energyTypeList[i].id == this.addForm.leixing) {
                        energyName = this.energyTypeList[i].name;
                    }
                }
                let param = {
                    name: this.addForm.name,
                    deviceIds: this.addForm.shebei.join(","),
                    des: this.addForm.miaoshu,
                    powerCategoryId: this.addForm.leixing,
                    powerCategoryName: energyName,
                    max: this.addForm.shangxian,
                    min: this.addForm.xiaxian,
                };
                if (this.editId == -1) {
                    addOutlineMeter(param).then((res) => {
                        if (res.data.code != 0) {
                            this.$message.warning(res.data.msg);
                        } else {
                            this.addPop = false;
                            this.getOutlineMeterList();
                        }
                    });
                } else {
                    param.id = this.editId;
                    editOutlineMeter(param).then((res) => {
                        if (res.data.code != 0) {
                            this.$message.warning(res.data.msg);
                        } else {
                            this.addPop = false;
                            this.getOutlineMeterList();
                        }
                    });
                }
            }
        },
    },
};
</script>

<style scoped>
.pop .detail {
    padding-right: 20px;
}
</style>
